.loader_container {
  .loader_img {
    @include flex-position(center, center);
    margin: 0 auto;
    width: 28px;
    animation: spin 0.4s ease-in-out infinite;
    -webkit-animation: spin 0.4s ease-in-out infinite;

    @keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        -webkit-transform: rotate(360deg);
      }
    }
  }
}
