.info_container {
  @include flex-position(flex-start, flex-start);
  margin-top: 6px;
  line-height: normal;

  svg {
    margin-right: 5px;
    width: 18px;
    height: 18px;

    path {
      fill: $color-gray;
    }
  }

  .infos {
    p {
      margin-bottom: 1px;
      font-size: 0.86em;
      color: $color-gray;
      font-style: italic;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
