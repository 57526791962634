.no_records_container {
  @include flex-position(center, center);
  flex-direction: column;
  padding-top: 100px;

  img {
    width: 120px;
    height: auto;
  }

  h6 {
    margin-top: 20px;
    font-weight: 500;
    font-size: 17px;
    font-style: italic;
    text-align: center;
    line-height: inherit;
    max-width: 380px;
    color: $color-purple-2;
  }
}
