.pagination {
  position: relative;
  justify-content: flex-end;
  margin: 0;
  padding: 11px 5px;
  width: 100%;
  border-top: 0.5px solid #eeeeee;

  @media screen and (max-width: 400px) {
    display: block;
    overflow: hidden;
    overflow-x: scroll;
  }

  li {
    display: inline;
    cursor: pointer;

    a {
      padding: 8px 14px;
      text-decoration: none;
      font-size: 0.9em;
      outline: none;
      background: $color-white;
      border: 1px solid $color-border-2;
      border-right: none;

      &:hover {
        color: $color-white !important;
        background-color: $color-purple;
      }
    }

    &:last-child {
      a {
        border-right: 1px solid $color-border-2;
      }
    }

    &.active a {
      color: $color-white;
      background-color: $color-purple;
    }

    &.disabled a {
      color: $color-white;
      cursor: not-allowed;
      background-color: $color-border-2;
    }
  }
}
