.navbar_container {
  @include flex-position(space-between, center);
  position: fixed;
  top: 0;
  left: 255px;
  padding: 11px 30px;
  width: 100%;
  max-width: 1545px;
  background: $color-white;
  border-bottom: 1px solid rgba(144, 151, 165, 0.1);
  z-index: 2;

  @media screen and (max-width: 850px) {
    left: 0px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &.full {
    left: 0px;
  }

  .sidebar_toggler {
    margin-right: 12px;
  }

  .title {
    font-size: 1em;
    font-weight: 500;
    width: 100%;
  }

  .dropdown_container {
    .user {
      @include flex-position(flex-start, center);

      .initials {
        @include flex-position(center, center);
        margin-right: 10px;
        width: 40px;
        height: 40px;
        color: $color-purple;
        border-radius: 50%;
        background: rgba(0, 25, 132, 0.2);
      }

      .info {
        text-align: left;

        h6 {
          @include ellipsis;
          margin-bottom: 1px;
          font-size: 0.95em;
          font-weight: 500;
          width: 140px;

          @media screen and (max-width: 450px) {
            width: 85px;
          }
        }

        p {
          font-size: 0.85em;
          font-weight: 400;
        }
      }
    }
  }
}
