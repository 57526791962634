.status_container {
  padding: 2px 10px;
  padding-bottom: 3px;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 0.875em;
  font-weight: 500;
  border-radius: 3px;
  text-transform: capitalize;

  &.success {
    color: $color-success;
    background: rgba(68, 175, 105, 0.1);
    border: 1px dashed rgba(68, 175, 105, 0.1);
  }

  &.secondary {
    color: $color-purple;
    background: rgba(0, 25, 132, 0.1);
    border: 1px dashed rgba(0, 25, 132, 0.1);
  }

  &.warning {
    color: $color-warning;
    background: rgba(255, 210, 76, 0.1);
    border: 1px dashed rgba(255, 210, 76, 0.1);
  }

  &.error {
    color: $color-error;
    background: rgba(211, 72, 41, 0.1);
    border: 1px dashed rgba(211, 72, 41, 0.1);
  }

  &.tertiary {
    color: $color-black;
    background: rgba(27, 27, 27, 0.1);
    border: 1px dashed rgba(27, 27, 27, 0.1);
  }
}
