.modal {
  background-color: none;
  // z-index: 10000;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;
    width: 600px;
    max-width: 600px;

    @media screen and (max-width: 600px) {
      padding: 20px 12px;
      width: 100%;
    }

    &.size_medium {
      width: 480px;
      max-width: 480px;
    }

    .modal-content {
      border: 1px solid $color-purple;
      background: $color-white;
      width: 100%;

      .section_header_container {
        margin: 0px;
        padding: 14px 24px;
        border-bottom: 1px solid $color-border;

        @media screen and (max-width: 380px) {
          padding: 14px 12px;
        }
      }

      .form {
        overflow-y: auto;
      }

      .body {
        margin-top: 25px;
        padding: 0px 24px;

        @media screen and (max-width: 380px) {
          padding: 0px 12px;
        }
      }

      .actions {
        @include flex-position(space-between, center);
        margin-top: 45px;
        padding: 9px 24px;
        border-top: 1px solid $color-border;

        @media screen and (max-width: 380px) {
          padding: 9px 12px;
        }

        .btn_secondary {
          padding: 13px 25px;
          font-weight: bold;
          width: 100%;
        }

        .btn_primary {
          margin: 0 0 0 auto;
          padding: 13px 25px;
          font-weight: bold;
          width: 100%;

          .loader_img {
            width: 25px;
          }
        }
      }
    }
  }

  .add_bulk_employee_modal {
    .body {
      padding: 0 !important;

      .download_container {
        margin-bottom: 75px;
        padding: 0px 24px;
        text-align: center;

        p {
          margin-bottom: 18px;
          font-size: 1.25em;
          font-weight: 500;
        }

        .btn_secondary {
          margin: 0 auto;
          font-size: 0.9em;
          font-weight: 600;
        }
      }

      .choose_container {
        margin: 0 auto;
        padding: 45px 40px;
        max-width: 370px;
        background: rgba(0, 25, 132, 0.05);
        border-radius: 4px;
      }
    }
  }

  .add_single_employee_modal {
    .form .body {
      @include grid-columns(repeat(2, 1fr), 0px);
      grid-column-gap: 20px;

      @media screen and (max-width: 450px) {
        @include grid-columns(repeat(1, 1fr), 0px);
      }

      &.preview_payment {
        .item {
          margin-bottom: 22px;

          p {
            margin-bottom: 5px;
            font-size: 0.95em;
            color: $color-gray;
          }

          h6 {
            font-weight: 400;
            font-size: 1.1em;
          }
        }
      }
    }
  }

  .fund_wallet_modal {
    .body {
      .email_amount_section {
        margin-bottom: 40px;
        padding: 20px 24px;
        text-align: center;
        background: rgba(0, 25, 132, 0.06);
        border-radius: 5px;

        p {
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 1.2em;
        }

        h6 {
          font-weight: bold;
          font-size: 2em;
        }
      }

      .note {
        margin: 0 auto;
        margin-bottom: 40px;
        text-align: center;
        max-width: 420px;
        font-size: 0.95em;
      }

      .bank_account_section {
        text-align: center;

        h6 {
          margin-bottom: 5px;
          font-weight: bold;
          font-size: 2.3em;
        }

        p {
          font-weight: 500;
          font-size: 1.2em;
        }
      }
    }
  }
}
