.onboarding_page {
  margin: 0 auto;
  max-width: 1100px;

  .logo_container {
    justify-content: flex-start;
    margin-bottom: 50px;

    img {
      width: 130px;
    }
  }

  .greeting {
    h6 {
      margin-bottom: 7px;
      font-size: 1.5em;
      font-weight: 400;
      letter-spacing: -0.1px;
    }

    p {
      font-size: 1em;
      color: $color-black-2;
    }
  }

  .onboarding_steps {
    @include grid-columns(2fr 3fr, 50px);
    margin-top: 60px;

    @media screen and (max-width: 767px) {
      @include grid-columns(1fr, 50px);
    }

    .navigations {
      @media screen and (max-width: 767px) {
        @include flex-position(flex-start, flex-start);
        width: 1400px;
      }

      .step {
        @include grid-columns(50px auto, 20px);
        margin-bottom: 40px;
        opacity: 0.4;

        @media screen and (max-width: 767px) {
          margin: 0;
        }

        &:last-child {
          margin: 0;
        }

        .number {
          @include flex-position(center, center);
          width: 50px;
          height: 50px;
          font-size: 1.5em;
          font-weight: 500;
          border: 1px solid $color-black;
          border-radius: 50%;
        }

        .info {
          text-align: left;

          h6 {
            margin-bottom: 7px;
            font-size: 1.125em;
            font-weight: 500;
            line-height: 100%;
          }

          p {
            font-size: 1em;
            font-weight: 400;
            line-height: 140%;
          }
        }

        &.active {
          opacity: 1;

          .number {
            color: $color-white;
            background: $color-purple;
            border: 1px solid $color-purple;
          }
        }

        &.done {
          .number {
            color: $color-white;
            background: $color-purple;
            border: 1px solid $color-purple;

            svg {
              width: 30px;
              height: 30px;

              path {
                fill: $color-white;
              }
            }
          }
        }
      }
    }

    .content {
      .form {
        .wallet_balance {
          margin-bottom: 40px;
          padding: 18px 24px;
          background: rgba(0, 25, 132, 0.06);
          border-radius: 5px;

          h6 {
            margin-bottom: 4px;
            font-weight: bold;
            font-size: 2.1875em;
          }

          p {
            font-size: 1em;
          }
        }

        .d_flex {
          @include grid-columns(1fr 1fr, 30px);

          &.amount_container {
            @include grid-columns(1fr auto, 30px);
            margin-bottom: 12px;

            @media screen and (max-width: 450px) {
              @include grid-columns(1fr auto, 20px);
            }

            .form_group_container {
              margin: 0;
            }

            .btn {
              margin: auto 0 0 auto;
              height: 46px;

              @media screen and (max-width: 450px) {
                padding: 11px 20px;
              }
            }
          }
        }

        .btn_primary {
          margin-top: 40px;
          padding-top: 16px;
          padding-bottom: 16px;
          width: 100%;
        }

        .link_to {
          margin: 0 auto;
          margin-top: 15px;
          text-align: center;
          width: fit-content;
          cursor: pointer;
        }
      }
    }
  }
}
