.fund_wallet_form {
  @include flex-position(space-between, flex-end);
  margin-bottom: 24px;
  padding: 24px 20px;
  padding-top: 16px;
  border: 1px solid rgba(144, 151, 165, 0.15);
  border-radius: 5px;

  @media screen and (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (max-width: 410px) {
    display: block;
    padding-top: 18px;
  }

  .form_group_container {
    margin: 0;

    label {
      font-size: 0.85em;
    }

    .form-group .form-control {
      padding: 9px 12px !important;
      font-size: 0.9em;
    }
  }

  .btn {
    margin-left: 20px;
    padding: 10px 20px;
    font-size: 0.9em;

    @media screen and (max-width: 410px) {
      margin-top: 15px;
      width: 100%;
    }
  }
}
