$font-family-dm: "DM Sans", sans-serif;

$color-black: #1b1b1b;
$color-black-2: #181819;
$color-gray: #9097a5;
$color-gray-2: #f2f2f2;
$color-purple: #001984;
$color-purple-2: #061a40;
$color-white: #ffffff;
$color-off-white: #f4f7ff;
$color-success: #44af69;
$color-error: #d34829;
$color-warning: #ffd24c;
$color-border: #dddddd;
$color-border-2: rgba(144, 151, 165, 0.2);

$radial-gradient: radial-gradient(
  280% 280% at 58.04% -56.02%,
  $color-purple 0%,
  $color-purple-2 86.39%
);
$box-shadow: 0px 167px 261px rgba(0, 0, 0, 0.0163356),
  0px 24.3219px 112.662px rgba(0, 0, 0, 0.0270554),
  0px 5.3147px 35.1536px rgba(0, 0, 0, 0.0385493),
  0px 0px 4.14686px rgba(0, 0, 0, 0.06);
