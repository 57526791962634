.wallet_page_container {
  .overview_container {
    align-items: flex-start;

    .fund_wallet_form {
      margin: 0;
      padding-top: 12px;
      padding-bottom: 20px;
      background: $color-white;
    }
  }
}
