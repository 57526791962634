.vendor_loading_container {
  margin-top: 45px;
  width: 100%;
  height: 100%;
  font-weight: 500;
  text-align: center;
}

.vendor_management_page_container {
  .overview_container {
    @include flex-position(space-between, center);
    margin-bottom: 40px;
    width: 100%;

    @media screen and (max-width: 1210px) {
      display: block;
    }

    .stats_container {
      @include flex-position(flex-start, center);
      padding: 13px 0px;
      background: $color-white;
      border: 1px solid $color-border-2;
      border-radius: 5px;

      @media screen and (max-width: 1210px) {
        margin-bottom: 25px;
        width: fit-content;
      }

      @media screen and (max-width: 470px) {
        padding: 0;
        display: block;
        width: 100%;
      }

      .item {
        padding: 9px 20px;
        min-width: 228px;
        border-right: 1px solid $color-border-2;

        @media screen and (max-width: 1000px) {
          min-width: fit-content;
        }

        @media screen and (max-width: 470px) {
          padding: 20px 20px;
          min-width: 100%;
          border: none;
          border-bottom: 1px solid $color-border-2;
        }

        &:last-child {
          border: none;
        }

        p {
          margin-bottom: 7px;
          font-weight: 500;
          font-size: 0.75em;
          text-transform: uppercase;
          color: $color-gray;
        }

        h6 {
          font-weight: 500;
          font-size: 1.25em;
        }
      }
    }

    .review_container {
      width: 100%;

      h6 {
        font-weight: 600;
        font-size: 1.15em;
      }

      p {
        margin-top: 7px;
        font-size: 0.9em;
        color: $color-gray;
      }
    }

    .dropdown_container {
      .btn_primary {
        font-size: 0.9em;

        .right_icon {
          margin-left: 10px;

          svg {
            width: 23px;
            height: 23px;
            transform: rotate(270deg);

            path {
              fill: $color-white;
            }
          }
        }
      }
    }
  }

  .table {
    @media screen and (max-width: 1070px) {
      width: 760px;
    }
  }
}

.vendor_information_page_container {
  @include grid-columns(300px 1fr, 30px);

  @media screen and (max-width: 1160px) {
    @include grid-columns(250px 1fr, 25px);
  }

  @media screen and (max-width: 700px) {
    display: block;
  }

  .profile_container {
    margin-top: 45px;
    padding: 0px 12px;
    background: $color-white;
    border-radius: 12px;
    border: 1px solid $color-border-2;

    .overview {
      width: 100%;
      text-align: center;

      img {
        margin-top: -45px;
        width: 85px;
        height: 85px;
        object-fit: contain;
        border: 1px solid $color-border-2;
        border-radius: 50%;
      }

      h6 {
        margin-top: 10px;
        font-weight: 600;
        font-size: 1.1em;
      }

      .status_container {
        margin: 0 auto;
        margin-top: 10px;
      }
    }

    .others {
      margin-top: 25px;

      @media screen and (max-width: 700px) {
        @include grid-columns(1fr 1fr, 0px);
      }

      @media screen and (max-width: 410px) {
        display: block;
      }

      .item {
        padding: 13px 0px;
        padding-bottom: 16px;
        border-top: 1px solid $color-border-2;

        @media screen and (max-width: 700px) {
          text-align: center;
        }

        @media screen and (max-width: 410px) {
          text-align: left;
        }

        p {
          margin-bottom: 5px;
          font-size: 0.85em;
          font-weight: 400;
        }

        h6 {
          font-size: 0.95em;
          font-weight: 500;
        }
      }
    }
  }

  .payments_container {
    position: relative;
    margin-top: 8px;
    overflow-x: scroll;

    @media screen and (max-width: 700px) {
      margin-top: 40px;
    }

    .title {
      margin-bottom: 17px;
      font-weight: 600;
      font-size: 1.1em;
    }

    .table {
      @media screen and (max-width: 1110px) {
        width: 515px;
      }

      @media screen and (max-width: 700px) {
        width: 100%;
      }

      @media screen and (max-width: 540px) {
        width: 515px;
      }
    }
  }
}
