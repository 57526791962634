.button_component {
  &.loading {
    padding: 8px 30px !important;
    padding-bottom: 9px !important;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
