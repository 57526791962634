.auth_left_section {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 400px;
  background: $radial-gradient;
  z-index: 1;

  @media screen and (max-width: 767px) {
    display: none;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0px 40px;
    width: 100%;

    .logo_container {
      margin-bottom: 30px;
      justify-content: flex-start;
    }

    h1 {
      font-weight: normal;
      font-size: 1.1em;
      line-height: 150%;
      color: $color-white;
    }
  }
}
