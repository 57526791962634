@import "../abstract/mixins.scss";
@import "../abstract/variables.scss";

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;

  &:before,
  &:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  background: $color-white;
  font-family: $font-family-dm;
  font-size: 1em;
  font-weight: normal;
}

.app {
  position: relative;
  background: $color-white;
  margin: 0 auto;
  min-height: 100vh;
}

.section {
  padding: 0px 15px;

  @media screen and (max-width: 380px) {
    padding: 0px 12px;
  }
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
span,
ul,
li,
label,
input,
textarea,
select {
  margin: 0;
  font-family: $font-family-dm !important;
  letter-spacing: 0.2px;
  color: $color-black;
}
a {
  letter-spacing: 0px;
}

ul {
  li {
    list-style: none;
    padding: 0;
  }
}

a {
  text-decoration: none;
  color: inherit;

  &:active,
  &:hover,
  &:focus {
    @extend a;
  }
}

svg.black {
  path {
    fill: $color-black;
  }
}

input,
select,
button,
.btn {
  &:focus,
  &:active {
    box-shadow: none !important;
    outline: 0 !important;
  }
}
input:focus,
select:focus {
  border: 1px solid $color-purple !important;
}
input::-webkit-input-placeholder {
  color: $color-gray !important;
}
input:-ms-input-placeholder {
  color: $color-gray !important;
}
input::placeholder {
  color: $color-gray !important;
}
.form-control[readonly] {
  background-color: rgba(0, 25, 132, 0.05);
}

.form-group {
  margin-bottom: 0;
}

.btn {
  @include flex-position(center, center);
  padding: 11px 30px;
  border-radius: 5px;
  font-weight: bold;

  .loader_img {
    margin-top: 0 !important;
  }
}

.btn_primary {
  @extend .btn;
  color: $color-white;
  background: $color-purple !important;
  border: 1px solid $color-border;

  &:hover,
  &:focus {
    @extend .btn_primary;
  }
}

.btn_secondary {
  @extend .btn;
  color: $color-purple;
  background: $color-white !important;
  border: 1px solid $color-purple;

  &:hover,
  &:focus {
    @extend .btn_secondary;
  }
}

.btn_error {
  @extend .btn;
  color: $color-error;
  background: $color-border !important;
  border: 1px solid $color-error;

  &:hover,
  &:focus {
    @extend .btn_error;
  }
}

.dashboard {
  @include flex-position(flex-start, flex-start);
  max-width: 1800px;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  &.hide_sidebar {
    .sidebar_container {
      display: none;
    }

    .dashboard_content {
      width: 100%;

      .navbar_container {
        width: 100%;
      }
    }
  }

  .dashboard_content {
    position: relative;
    margin: 0 0 0 auto;
    padding: 103px 30px 60px 30px;
    width: calc(100% - 255px);
    min-height: 100vh;
    background: rgba(0, 25, 132, 0.02);

    .navbar_container {
      width: calc(100% - 255px);
    }

    @media screen and (max-width: 850px) {
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;

      .navbar_container {
        width: 100%;
      }
    }
  }

  .loader_container {
    padding-top: 40px;

    .loader_img {
      width: 35px;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }
}

.vertical_scroll {
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0px;
    height: 0px;
  }
}
