.nav-tabs {
  padding-bottom: 1px;
  width: 100%;
  background: $color-white;
  border: 1px solid $color-border;
  border-radius: 30px;

  .nav-item {
    width: 50%;

    .nav-link {
      padding: 8px 10px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.02em;
      color: $color-black;
      background: $color-white;
      border: none;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        border: none;
      }

      &.active {
        color: $color-white;
        background: $color-purple-2;
      }
    }
  }
}
