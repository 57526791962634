.employee_information_page_container {
  .employee_overview {
    @include flex-position(flex-start, center);
    margin-bottom: 40px;
    padding: 15px 25px;
    background: $color-white;
    border: 1px solid $color-border-2;
    border-radius: 5px;

    img {
      margin-right: 20px;
      width: 65px;
      height: 65px;
      object-fit: contain;
      border: 1px solid $color-border-2;
      border-radius: 50%;
    }

    .info {
      h6 {
        margin-bottom: 4px;
        font-weight: 600;
        font-size: 1.1em;
      }

      p {
        font-size: 0.95em;
      }
    }

    .status_container {
      margin: 0 0 0 auto;
    }
  }

  .employee_information {
    padding: 14px 25px;
    background: $color-white;
    border: 1px solid $color-border-2;
    border-radius: 5px;

    .nav_container {
      margin-bottom: 20px;

      .nav_tabs {
        @include flex-position(flex-start, center);
        border-bottom: 1px solid $color-border-2;
        width: 1070px;

        .nav {
          display: block;
          margin-right: 25px;
          padding: 12px 0px;
          padding-top: 0px;
          font-size: 0.95em;
          opacity: 0.7;
          cursor: pointer;

          &:last-child {
            margin: 0;
          }

          &.active {
            padding-bottom: 9px;
            font-weight: 500;
            color: $color-purple-2;
            border-bottom: 3px solid $color-purple-2;
            opacity: 1;
          }
        }
      }
    }

    .tab_content {
      margin-bottom: 15px;
      padding: 24px 25px;
      padding-bottom: 45px;
      background: $color-white;
      border: 1px solid $color-border-2;
      border-radius: 5px;

      .dropdown_container {
        .btn_secondary {
          padding: 7px 12px;
          font-size: 0.9em;
          font-weight: 500;

          svg {
            margin-top: 1px;
            width: 20px;
            height: 20px;
            margin-left: 5px;
            transform: rotate(-90deg);
          }
        }
      }

      .btn_primary.add_new {
        margin: 0 0 0 auto;
        padding: 8px 18px;
        font-size: 0.9em;
        font-weight: 500;
      }

      .infos {
        @include grid-columns(repeat(2, 1fr), 30px);

        .info {
          p {
            margin-bottom: 3px;
            font-size: 0.95em;
          }

          h6 {
            font-weight: 500;
          }
        }
      }
    }
  }
}
