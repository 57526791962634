select {
  padding-top: 4px !important;
  padding-left: 8px !important;
}

.select_container__control {
  padding: 4px 0 4px 8px;
  width: 100%;
  font-size: 1em;
  border: 1px solid $color-border !important;
  border-radius: 10px !important;
  color: $color-black !important;
  outline: none;
  background: transparent;
  cursor: pointer;

  &.select_container__control--is-focused {
    box-shadow: none !important;
    border: 1px solid $color-purple !important;
  }

  .select_container__value-container {
    padding: 0;
  }

  .select_container__indicators {
    padding: 0;

    .select_container__indicator-separator {
      display: none;

      .select_container__indicator {
        padding: 0;
      }
    }
  }
}

.select_container__menu .select_container__option {
  cursor: pointer;
}
