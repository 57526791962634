.back_container {
  @include flex-position(center, center);
  position: sticky;
  top: 18px;
  margin-left: 12px;
  margin-bottom: 45px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid $color-border;
  border-radius: 50%;
  box-shadow: $box-shadow;
  cursor: pointer;
  z-index: 2;

  svg {
    width: 22px;
    height: 22px;

    path {
      fill: $color-black;
    }
  }
}
