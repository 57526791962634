.home_page_container {
  .greeting_container {
    @include flex-position(space-between, center);

    @media screen and (max-width: 930px) {
      display: block;
      margin-bottom: 50px;
    }

    h6 {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 1.125em;
      letter-spacing: 0px;
    }

    p {
      font-size: 1em;

      @media screen and (max-width: 930px) {
        margin-bottom: 15px;
      }
    }

    .btn {
      font-size: 0.9em;
    }
  }

  .switch_dashboard_view {
    margin: 30px 0px;
    max-width: 200px;

    .select_container__control {
      padding-left: 10px;
      font-weight: 500;
      font-size: 0.95em;
      cursor: pointer;
    }

    .select_container__menu {
      font-size: 0.95em;
    }
  }

  .stats_container {
    @include grid-columns(repeat(4, 1fr), 35px);
    margin-bottom: 25px;

    @media screen and (max-width: 1430px) {
      @include grid-columns(repeat(2, 1fr), 25px);
    }

    @media screen and (max-width: 560px) {
      @include grid-columns(repeat(1, 1fr), 20px);
    }

    .item {
      @include flex-position(space-between, center);
      padding: 24px 20px;
      background: $color-white;
      border: 1px solid $color-border-2;
      border-radius: 5px;

      .left_side {
        p {
          margin-bottom: 7px;
          font-weight: 500;
          font-size: 0.75em;
          text-transform: uppercase;
          color: $color-gray;
        }

        h6 {
          font-weight: 500;
          font-size: 1.25em;
        }
      }

      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  .stats_chart_container {
    @include grid-columns(3fr 2fr, 40px);
    padding: 24px 25px;
    padding-bottom: 20px;
    background: #ffffff;
    border: 1px solid $color-border-2;
    border-radius: 5px;

    @media screen and (max-width: 1300px) {
      @include grid-columns(1fr, 40px);
    }

    @media screen and (max-width: 450px) {
      padding: 24px 12px;
    }

    .chart_container {
      .header {
        @include flex-position(space-between, center);

        .select_container__control {
          padding: 0px 0px 1px 9px;
          font-weight: 500;
          font-size: 0.8em;
          background: rgba(245, 248, 255, 0.5);
          cursor: pointer;
        }

        .select_container__menu {
          font-size: 0.85em;
        }
      }

      .chart {
        margin-top: -10px;
        width: 100%;
        height: 445px;

        @media screen and (max-width: 450px) {
          margin-top: 10px;
          height: 345px;
        }

        @media screen and (max-width: 380px) {
          margin: auto;
          width: 88%;
        }
      }
    }

    .right_side {
      .chart_container {
        padding: 16px 20px 10px 20px;
        border: 1px solid rgba(144, 151, 165, 0.15);
        border-radius: 5px;

        @media screen and (max-width: 450px) {
          padding-left: 15px;
          padding-right: 15px;
        }

        .chart {
          margin-top: 10px;
          width: 100%;
          height: 250px;

          @media screen and (max-width: 450px) {
            margin-top: 25px;
            height: 350px;
          }

          @media screen and (max-width: 380px) {
            margin: auto;
            width: 88%;
          }
        }
      }
    }
  }
}
