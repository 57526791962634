.sign_in_page {
  @include flex-position(flex-start, flex-start);
  width: 100%;

  &.register_page {
    @media screen and (max-height: 1105px) {
      .form_container {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
    }
  }

  .form_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding-left: 400px;
    width: 100%;

    @media screen and (max-width: 767px) {
      padding: 0px;
    }

    @media screen and (max-width: 450px) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }

    .form {
      margin: 0 auto;
      padding: 50px 12px;
      max-width: 500px;

      .top_section {
        text-align: center;

        h5 {
          margin-bottom: 15px;
          font-size: 2.1em;
          color: $color-purple;
          letter-spacing: -0.5px;
        }

        h6 {
          margin-bottom: 50px;
          font-weight: 400;
          color: $color-gray;

          a {
            font-weight: 500;
            color: $color-purple;
          }
        }

        .btn {
          margin-bottom: 35px;
          padding: 12px 10px;
          color: $color-black;
          background: $color-white !important;
          text-transform: none;

          svg {
            margin-right: 12px;
          }
        }

        .or_intersection {
          @include flex-position(center, center);
          margin-bottom: 30px;

          .line {
            background: $color-border;
            width: 100%;
            height: 1px;
          }

          p {
            margin-top: -3px;
            padding: 0px 10px;
          }
        }
      }

      .d_flex {
        @include flex-position(flex-start, flex-start);

        .form_group_container {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .remember_section {
        margin-top: -8px;

        .form_group_container {
          margin: 0;
        }

        .forgot_password {
          margin: 0 0 0 auto;
          font-size: 0.95em;

          a {
            text-align: right;
            color: $color-purple;
            font-weight: 500;
          }
        }
      }

      .btn_primary {
        margin-top: 40px;
        padding: 15px 10px;
        width: 100%;
        text-transform: uppercase;
      }
    }
  }
}
