.sidebar_container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0px;
  padding-bottom: 40px;
  height: 100vh;
  width: 255px;
  overflow-y: scroll;
  background: $color-white;
  border-right: 1px solid rgba(144, 151, 165, 0.1);
  z-index: 1;
  display: none;

  .padding_left_right {
    padding-right: 20px;
    padding-left: 20px;
  }

  &.open {
    display: block;

    @media screen and (max-width: 850px) {
      &::after {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin-left: 255px;
        content: "";
        background: rgba(0, 0, 0, 0.45);
      }
    }
  }

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0px;
  }

  .logo_container {
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 30px;

    @media screen and (max-width: 850px) {
      margin-top: 83px;
    }

    img {
      width: auto;
      height: 33px;
    }
  }

  .form_group_container {
    margin-bottom: 32px;

    .select_container__control {
      cursor: pointer;

      .select_container__single-value {
        font-weight: 500;
      }
    }
  }

  .links_container {
    .link {
      display: block;
      margin-bottom: 10px;
      cursor: auto;

      &:last-child {
        margin: 0;
      }

      .d_flex {
        @include flex-position(space-between, center);
        padding-top: 12px;
        padding-bottom: 12px;
        cursor: pointer;
        border-right: 5px solid $color-white;

        .info {
          @include flex-position(flex-start, center);

          svg {
            margin-right: 12px;
          }
        }

        span svg {
          transform: rotate(270deg);
          opacity: 0.8;
          width: 21px;
          height: 21px;
        }

        span.rotate svg {
          margin-top: -3px;
          transform: rotate(90deg);
        }
      }

      &.active {
        .d_flex {
          background: rgba(0, 25, 132, 0.05);
          border-right: 5px solid $color-purple;

          svg path {
            fill: $color-purple;
          }

          .info {
            p {
              font-weight: 500;
              color: $color-purple;
            }
          }
        }
      }

      .inner_links {
        padding-top: 18px;
        padding-bottom: 20px;
        padding-left: 56px;
        border-bottom: 1px solid rgba(144, 151, 165, 0.1);

        .link {
          margin-bottom: 15px;
          font-size: 0.95em;
          cursor: pointer;

          &:last-child {
            margin: 0;
          }

          &.active {
            font-weight: 500;
            color: $color-purple;
          }
        }
      }
    }
  }
}
